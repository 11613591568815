import { createWebHistory, createRouter } from "vue-router";
import routes from './routes';
import appConfig from "../../app.config";
import store from '@/state/store';

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

// API based Authentication
router.beforeEach(async (routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  if (!authRequired) return next();
  if (store.state.currentUser === null) {
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
  } else {
    if (store.state.currentUser.enabled === true) next();
    else next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
  }
});

router.beforeEach((routeTo, routeFrom, next) => {
  const publicPages = ['/login','/logout', '/register', '/forgot-password'];
  const authPage = !publicPages.includes(routeTo.path);
  const loggedUser = localStorage.getItem('currentUser');

  if (authPage && !loggedUser) {
    return next('/login');
  }

  const user = store.state.currentUser;

  if (routeTo.meta.authRequired === true) {
    // Access check based on permissions
    if (
        user.type.nom === 'Super Admin' ||
        user.type.permissions.some(p => p === routeTo.meta.key)
    ) {
      return next();
    } else {
      return next({ name: 'Unauthorized' });

    }
  } else {
    return next();
  }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    }
  } catch (error) {
    return;
  }
  document.title = routeTo.meta.title + ' | ' + appConfig.title;
  next();
});

export default router;
